.page-cms {
  .article {
    background-color: $gray-200;
    height: 100%;

    .article__cover {
      position: relative;
      display: block;
      overflow: hidden;
      height: 250px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }

      &:after {
        content: '\A';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .article__infos {
      padding: 1rem;
    }

    .article__title, .article__published, .article__summary {
      margin: 0;
    }

    .article__published {
      margin: 1rem 0;
      font-size: .75rem;
    }
  }
}

.page-post {
  .cover {
    position: relative;
    overflow: hidden;
    height: 400px;

    &:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .img-fluid {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  .post-title {
    position: absolute;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
      border: solid 0.2rem #fff;
      border-left: 0;
      border-right: 0;
    }
  }
}
