#preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
#preview img {
    max-width: 200px;
    max-height: 200px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.multiselect {
    margin-bottom: -0.5rem;
}

.multiselect__tags{
    background: #ededed!important;
}
.multiselect__tags span{
    background-color: transparent;
    font-family: montserrat-regular;
    color: #6c757d;
    font-size: 1rem;
    padding-top: 0;
    margin-bottom: 0px;
}
.multiselect__input {
 background-color: #ededed!important;
}
.multiselect__spinner{
    background: transparent!important;
}

.multiselect__single,
.multiselect__input {
    padding: 2px 0 0 0;
}
